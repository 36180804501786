export const endpoint='https://dev.ppcentourage.com/api'
export const SP_APP_ID='amzn1.sp.solution.df087c71-0a43-4f06-a0ea-1457d3e4e93b'
export const SP_BETA='FALSE'
export const SP_REDIRECT_URI='https://dev.ppcentourage.com/signup-complete'
export const SP_SETTINGS_REDIRECT_URI='https://dev.ppcentourage.com/settings'
export const LOGIN_CLIENT_ID='amzn1.application-oa2-client.f250e3787a2b4105bd5881a6d0f0e3a9'
export const STRIPE_PUB_KEY='pk_test_dOBDXPFtouzDeStoJnwOSEQF'
export const MARGINS_URL='https://app.ppcentourage.com/margins'
export const AUTH0_DOMAIN='dev-login.carbon6.io'
export const AUTH0_CLIENT_ID='oafw6Vwpzy8A3o4F17XAY7iY7blNbgTM'
export const AUTH0_CALLBACK_URL='https://dev.ppcentourage.com/auth'
export const AUTH0_AUDIENCE='https://dev.app.carbon6.io'
export const AUTH0_LOGOUT_URL='https://dev.ppcentourage.com/login'
export const AUDIT_ORCHESTRATOR_URL='https://dev-auth.carbon6.io/audit/orchestrator'
export const C6_NAVBAR_SCRIPT='https://dev-navbar.carbon6.io/dev/c6-navbar.js'
export const AMAZON_TEST_ACCOUNT='false'
